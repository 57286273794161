<template>
  <div>
    <lz-dialog
      :dialogBtns="dialogBtns"
      title="选择虚拟产品"
      @dialog-btn-click="handleCommonBtnCLick"
      ref="lzDialog">
      <lz-list
        :config="config"
        :table="table"
        :show-search="false"
        @search-btn-click="handleCommonBtnCLick"
        @table-btn-click="handleCommonBtnCLick"
        :is-router="false"
        :search-btn="searchBtn"
        :search="search"
        ref="lzList">
      </lz-list>
    </lz-dialog>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      default: '',
    },
  },
  data() {
    return {
      search: [
        {
          fieldName: 'goods_id',
          type: 'select',
          name: '请输入产品',
          width: '300px',
          value: '',
          options: [],
          remoteMethod: (val) => {
            this.$baseHttp.get(`/goods/list?keyword=${val}`).then((response) => {
              const goods = this.search.find((r) => r.fieldName === 'goods_id');
              this.$set(goods, 'options', response.data.data.map((r) => ({
                ...r,
                htmlButton: [{ name: '删除', key: 'delete', type: 'danger' }],
                label: r.title,
                value: r.id,
                quantity: 0,
              })));
            });
          },
        },
      ],
      searchBtn: [
        { name: '添加', type: 'danger', key: 'add' },
      ],
      table: [
        { name: 'id', prop: 'id' },
        { name: '名称', prop: 'title' },
        { name: '型号', prop: 'model' },
        { name: '品牌', prop: 'brand_name' },
        { name: '库存', prop: 'stock' },
        { name: '数量', prop: 'quantity', type: 'edit', editType: 'number', max: 'stock', width: '200px' },
        { name: '操作', prop: 'htmlButton' },
      ],
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      form: [],
      config: {
        url: '#',
      },
    };
  },
  methods: {
    init() {
      this.$refs.lzDialog.dialogVisible = true;
    },
    async handleCommonBtnCLick(type, element) {
      const lzList = this.$refs.lzList.list;
      switch (type) {
        case 'add': {
          if (this.search[0].value === '') return;
          const target = this.search[0].options.find((r) => String(r.value) === this.search[0].value);
          const hasGoods = lzList.some((r) => String(r.value) === this.search[0].value);
          if (hasGoods) {
            this.$message.error('存在重复商品');
            return;
          }
          lzList.push(target);
        }
          break;
        case 'delete': {
          const index = lzList.findIndex((r) => String(r.value) === element.value);
          lzList.splice(index, 1);
        }
          break;
        case 'save': {
          if (lzList.length === 0) return;
          const response = await this.$baseHttp.post('/order/action', {
            id: this.id,
            type: 'ship',
            value: 1,
            goods_id: lzList.map((v) => v.id).toString(),
            quantity: lzList.map((v) => v.quantity).toString(),
          });
          if (!response) return;
          this.$message.success('操作成功');
          this.$emit('success');
        }
          break;
        default:
          break;
      }
    },
  },
};
</script>
