<template>
  <div>
    <lz-dialog
      :dialogBtns="[]"
      :title="title"
      ref="lzDetailDialog">
      <div v-if="showPage">
        <el-alert
          v-if="info.question_type != 0"
          class="mb-20"
          :title="`${info.question_time}: 修改状态为 ${info.question_name}`"
          :description="info.question_memo"
          type="warning"
          show-icon>
        </el-alert>
        <el-alert
          v-if="info.is_cancel"
          class="mb-20"
          title="已作废"
          type="warning"
          show-icon>
        </el-alert>
        <template v-for="parent in formList">
          <lz-card :title="parent.name" :key="parent.value">
            <template v-if="parent.value == 'base'">
              <el-button type="primary" slot="right" @click="$refs.lzDialogOrderView.dialogVisible = true" v-if="!isShipping">查看订单</el-button>
            </template>
            <lz-form :form="parent.list" :col="3" ref="lzForm" @change-select="handleChangeSelect"></lz-form>
          </lz-card>
        </template>
        <lz-card title="兑换列表">
          <el-button type="primary" slot="right" v-if="info.btn_change_product" @click="handleGetProduct()">替换产品</el-button>
          <el-table
            :data="info.goods"
            :header-cell-style="{backgroundColor: '#f6f8fa'}"
            border>
            <el-table-column label="产品">
              <template slot-scope="scope">
                <div data-flex="cross:center dir:top">
                  <el-image style="width: 50px; height: 50px" :src="scope.row.thumb" fit="fill"></el-image>
                  <el-link
                    type="primary"
                    style="font-size: 12px;"
                    @click.stop="handlePreview(scope.row.id)">
                    {{ scope.row.title }}
                  </el-link>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="100px" label="规格名称">
              <template slot-scope="scope">
                <div data-flex="cross:center dir:top">
                  <el-image v-if="scope.row.spec.pic" style="width: 50px; height: 50px" :src="scope.row.spec.pic" fit="fill"></el-image>
                  <span>{{ scope.row.spec.name }}{{ scope.row.spec.memo }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="exchange_num" width="100px" label="兑换个数"></el-table-column>
            <el-table-column prop="spec.nums" width="100px" label="几次发货"></el-table-column>
            <el-table-column v-if="deliveryType == 1 && supplier" width="100px" label="代发价格">
              <template slot-scope="scope"><span :class="scope.row.$index ">{{ supplier.shipping_price }}</span>
              </template>
            </el-table-column>
            <el-table-column label="是否虚拟产品" width="100px">
              <template slot-scope="scope">
                {{ scope.row.is_virtual ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column v-if="deliveryType == 0 && supplier" width="100px" label="备货价格">
              <template slot-scope="scope"><span :class="scope.row.$index ">{{ supplier.stocking_price }}</span>
              </template>
            </el-table-column>
            <el-table-column label="实际发货价" width="200px">
              <template slot-scope="scope">
                <el-input-number
                  :min="0"
                  v-model="scope.row.price"
                  clearable
                  placeholder="实际发货价"
                  style="width: 100%;">
                </el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="税运" width="200px">
              <template slot-scope="scope">
                <el-select
                  clearable
                  filterable
                  style="width: 100%;"
                  v-model="scope.row.tax_type">
                  <el-option label="含税含运费" :value="1"></el-option>
                  <el-option label="含税不含运" :value="2"></el-option>
                  <el-option label="含运不含税" :value="3"></el-option>
                  <el-option label="不含税运" :value="4"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100" v-if="info.btn_change_product">
              <template slot-scope="scope">
                <el-link
                  v-if="info.specs && info.specs.length > 1"
                  :underline="false"
                  class="list-button"
                  @click="handleGetProduct(scope.row)"
                  type="danger">
                  替换
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </lz-card>
        <lz-card title="快递信息">
          <el-button type="primary" :disabled="expressDisabled" slot="right" @click="handleAddExpress">新增</el-button>
          <el-table
            :data="info.express"
            :header-cell-style="{backgroundColor: '#f6f8fa'}"
            border>
            <el-table-column label="快递公司">
              <template slot-scope="scope">
                <el-select
                  filterable
                  class="w100"
                  v-model="scope.row.express_id">
                  <el-option
                    v-for="item in expressList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="快递费用">
              <template slot-scope="scope">
                <el-input-number :min="0" v-model="scope.row.express_price" clearable placeholder="快递费用" style="width: 100%;"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="快递单号">
              <template slot-scope="scope">
                <el-input v-model="scope.row.express_sn" clearable placeholder="请输入快递单号"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="备注">
              <template slot-scope="scope">
                <el-input v-model="scope.row.memo" clearable placeholder="请输入备注"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <el-link
                  v-if="scope.row.id != undefined && $route.name !== 'order-shipping'"
                  :underline="false"
                  style="margin-right: 10px;"
                  @click="$refs.action.init('sms', info.id, scope.row.id);"
                  type="warning">
                  发短信
                </el-link>
                <el-link
                  v-if="scope.row.id != undefined"
                  :underline="false"
                  style="margin-right: 10px;"
                  @click="$refs.action.handleGetExpress(scope.row)"
                  type="warning">
                  查询
                </el-link>
                <el-link
                  :underline="false"
                  @click="info.express.splice(scope.$index, 1)"
                  type="danger">
                  删除
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </lz-card>
        <action ref="action" type="order-view" @success="getData()"></action>
        <sms-list ref="smsList"></sms-list>
        <virtual ref="virtual" :id="id" @success="getData()"></virtual>
        <preview ref="preview"></preview>
        <lz-dialog
          title="替换产品"
          :dialogBtns="[
            { key: 'replaceProduct', name: '替换产品', type: 'primary' },
          ]"
          @dialog-btn-click="handleCommonBtnCLick"
          ref="lzDialog">
          <lz-select-product
            ref="selectProduct"
            :exchange="info.exchange_num"
            :selectGoods="product.goods.goods"
            :addGoods="product.goods.add_goods"
            @preview-product="handlePreview"
            :total="total"
            :goods="goods">
          </lz-select-product>
        </lz-dialog>
      </div>
      <template slot="footer" v-if="showPage">
        <el-button type="success" v-if="info.is_edit" @click="handleSubmit">确定保存</el-button>
        <el-button type="danger" v-if="info.btn_ship" @click="handleConfirm('ship')">确认发货</el-button>
        <el-button type="danger" v-if="info.btn_end" @click="handleConfirm('end')">完结订单</el-button>
        <el-button type="danger" v-if="info.btn_question" @click="handleConfirm('question')">修改状态</el-button>
        <el-button type="danger" v-if="info.btn_cancel" @click="handleConfirm('cancel')">作废</el-button>
        <el-button type="warning" v-if="info.btn_sms && $route.name !== 'order-shipping'" @click="handleConfirm('sms')">发送短信</el-button>
        <el-button type="warning" v-if="info.sms_count > 0 && $route.name !== 'order-shipping'" @click="handleConfirm('smsList')">短信记录</el-button>
      </template>
    </lz-dialog>
    <lz-dialog
      title="在线预览"
      :width="400"
      :is-close-btn="false"
      ref="lzDialogOrderView">
      <div data-flex="main:center">
        <iframe scrolling="yes" frameborder="0" :src="config.giftUrl +'/gift/list/' + info.ticket_id" style="height: 667px;width: 375px"></iframe>
      </div>
    </lz-dialog>
  </div>
</template>
<script>
import config from '@/config';
import { formGetValue } from '@/utils/form';
import preview from '@/views/product/preview';
import action from './action';
import smsList from './smsList';
import virtual from './virtual';

const status = [
  { label: '是', value: 1 },
  { label: '否', value: 0 },
];

const type = [
  { label: '兑换券', value: 1 },
  { label: '礼品券', value: 2 },
];

const orderType = [
  { label: '人工兑换', value: 1 },
  { label: '客户兑换', value: 2 },
];

const deliveryType = [
  { label: '代发', value: 1 },
  { label: '自备货', value: 0 },
];

export default {
  components: {
    action,
    smsList,
    virtual,
    preview,
  },
  data() {
    return {
      config,
      id: '',
      title: '',
      info: {
        express: [],
      },
      showPage: false,
      expressList: [],
      product: {
        goods: {
          goods: [],
          add_goods: [],
        },
      },
      goods: [],
      formList: [],
    };
  },
  computed: {
    supplier() {
      const element = this.formList[2].list[1].options.find((r) => String(r.value) === String(this.formList[2].list[1].value));
      return element;
    },
    total() {
      return this.goods.reduce((total, item) => Number(total) + (Number(item.exchange_num)), 0);
    },
    expressDisabled() {
      return this.info.express.some((v) => v.express_id === '' || v.express_price === '' || v.express_sn === '');
    },
    deliveryType() {
      return this.formList[2].list[0].value;
    },
    isShipping() {
      return this.$route.name === 'order-shipping';
    },
  },
  mounted() {
    this.$baseHttp.get('/express/list?page_size=1000').then((response) => {
      this.expressList = response.data.data;
    });
    // this.getData();
  },
  methods: {
    init(id) {
      this.$refs.lzDetailDialog.dialogVisible = true;
      // this.$refs.lzDetailDialog.fullscreen = true;
      this.setDefault();
      this.getData(id);
    },
    getData(id) {
      if (id) this.id = id;
      this.showPage = false;
      this.$baseHttp.get(`/order/detail?id=${this.id}`).then((response) => {
        if (!response) return;
        this.info = response.data;
        this.title = this.isShipping ? '详情' : `${this.info.code}详情`;
        // this.$baseTitle(`${this.info.recipient_name}-${this.info.code}`);
        this.showPage = true;
        this.formList.forEach((parent, index) => {
          if (parent.value === 'shipping') {
            this.$set(parent.list[1], 'options', this.info.supplier.map((r) => ({
              ...r,
              label: r.name,
              value: Number(r.id),
            })));
          }
          if (parent.value !== 'base') {
            parent.list.forEach((element) => {
              this.$set(element, 'disabled', !!this.info.is_ship);
              if (element.fieldName === 'is_checkout') {
                this.$set(element, 'disabled', false);
              }
            });
          }
          this.$nextTick(() => {
            this.$refs.lzForm[index].setValue({
              data: response.data,
            });
          });
        });
      });
      if (!id) {
        this.$emit('success', 'save', {
          id: this.id,
        });
      }
    },
    handleChangeSelect(element) {
      if (element.fieldName !== 'delivery_type') return;
      const value = element.value;
      const target = this.formList[2].list[1];
      this.$set(target, 'value', value === '0' ? '0' : '');
      this.$set(target, 'options', target.options.map((r) => ({
        ...r,
        disabled: value === '0' ? String(r.id) !== '0' : String(r.id) === '0',
      })));
    },
    handleAddExpress() {
      this.info.express.push({
        express_id: '',
        express_price: 0,
        express_sn: '',
        memo: '',
      });
    },
    handleSubmit() {
      const allError = [];
      let data = {
        id: this.info.id,
        express: this.info.express,
        goods: this.info.goods,
      };
      this.formList.forEach((parent, index) => {
        if (index === 0) return;
        const { params, error } = formGetValue(parent.list);
        allError.push(...error);
        data = Object.assign(params, data);
      });
      if (allError.length > 0) {
        this.$message.error(`${allError[0].name}格式有误`);
        return;
      }
      this.$baseConfirm({
        content: '是否确定保存',
        callConfirm: async () => {
          const response = await this.$baseHttp.post(this.isShipping ? '/shipping/order/update' : '/order/update', data);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzDetailDialog.dialogVisible = false;
          this.getData();
        },
      });
    },
    handleConfirm(type) {
      // 发送短信
      if (type === 'sms') {
        this.$refs.action.init('sms', this.info.id);
        return;
      }
      // 修改状态
      if (type === 'question') {
        this.$refs.action.questionList = [];
        Object.keys(this.info.question).forEach((key) => {
          this.$refs.action.questionList.push({
            label: this.info.question[key],
            value: key,
          });
        });
        this.$refs.action.init('question', this.info.id);
        return;
      }
      // 产品判断是否有虚拟商品
      const hasvirtual = this.info.goods.some((r) => r.is_virtual === 1);
      if (hasvirtual && type === 'ship') {
        this.$refs.virtual.init(this.info.id);
        return;
      }
      // 短信记录
      if (type === 'smsList') {
        this.$refs.smsList.init(this.info.id);
        return;
      }
      let title = '';
      switch (type) {
        case 'end':
          title = '是否完结订单?';
          break;
        case 'ship':
          title = '是否发货?';
          break;
        case 'cancel':
          title = '是否作废';
          break;
        default:
          break;
      }
      this.$baseConfirm({
        content: title,
        callConfirm: async () => {
          const response = await this.$baseHttp.post('/order/action', {
            id: this.info.id,
            type,
            value: 1,
          });
          if (!response) return;
          this.$message.success('操作成功');
          this.getData();
        },
      });
    },
    async handleGetProduct(element) {
      let response;
      if (this.$route.name === 'order-shipping') {
        response = {
          data: {
            goods: {
              goods: [],
              add_goods: [],
              out_goods: [],
            },
          },
        };
      } else {
        const params = {
          id: this.info.code_id,
        };
        response = await this.$baseHttp.get('/order/code-detail', { params });
        if (!response) return;
        this.goods = [];
        [
          ...response.data.goods.goods || [],
          ...response.data.goods.add_goods || [],
        ].forEach((element) => {
          const hasOutGoods = response.data.goods.out_goods.find((r) => r.id === element.id);
          if (hasOutGoods) {
            this.$set(element, 'memo', hasOutGoods.memo);
            this.$set(element, 'is_global', hasOutGoods.is_global);
          }
        });
      }
      this.product = response.data;
      this.$refs.lzDialog.dialogVisible = true;
      if (element) {
        setTimeout(() => {
          const target = [
            ...response.data.goods.goods,
            ...response.data.goods.add_goods,
          ].find((r) => r.id === element.id);
          if (target) this.$refs.selectProduct.handleClickProduct(target);
        });
      }
    },
    handleCommonBtnCLick() {
      if (this.goods.length === 0) {
        this.$message.error('请选择要替换的产品');
        return;
      }
      this.$baseConfirm({
        content: '是否确定更换产品',
        callConfirm: async () => {
          const params = {
            goods: this.goods.map((r) => ({
              id: r.id,
              exchange_num: r.exchange_num,
              spec_id: r.spec_id,
              nums: r.nums,
            })),
            id: this.info.id,
          };
          const response = await this.$baseHttp.post(this.isShipping ? '/shipping/order/update' : '/order/update', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.getData();
        },
      });
    },
    handlePreview(id) {
      this.$refs.preview.init(id);
    },
    setDefault() {
      this.title = '';
      this.info = {
        express: [],
      };
      this.product = {
        goods: {
          goods: [],
          add_goods: [],
        },
      };
      this.goods = [];
      this.showPage = false;
      this.formList = [
        {
          name: '基础信息',
          value: 'base',
          list: [
            { name: '兑换码', fieldName: 'code', value: '', type: 'text', disabled: true, hide: this.isShipping },
            { name: '密码', fieldName: 'pwd', value: '', type: 'text', disabled: true, hide: this.isShipping },
            { name: '客户名称', fieldName: 'company_name', value: '', type: 'text', disabled: true, hide: this.isShipping },
            { name: '年份', fieldName: 'year', value: '', type: 'text', disabled: true, hide: this.isShipping },
            // {
            //   name: '兑换码类型',
            //   type: 'select',
            //   fieldName: 'type',
            //   value: [],
            //   options: [],
            //   remote: true,
            //   required: true,
            //   props: {
            //     url: '/ticket-type/list',
            //     value: 'id',
            //     label: 'name',
            //   },
            // },
            { name: '兑换码类型', fieldName: 'type', value: '', type: 'select', disabled: true, options: type, hide: this.isShipping },
            { name: '直客价格', fieldName: 'price_title_name', value: '', type: 'text', disabled: true, hide: this.isShipping },
            { name: '产品用途', fieldName: 'purpose_name', value: '', type: 'text', disabled: true, hide: this.isShipping },
            { name: '应用场景', fieldName: 'scene_name', value: '', type: 'text', disabled: true, hide: this.isShipping },
            { name: '兑换数量', fieldName: 'exchange_num', value: '', type: 'text', disabled: true },
            { name: '短信发送次数', fieldName: 'sms_count', value: '', type: 'text', disabled: true },
            { name: '兑换日期', fieldName: 'create_time', value: '', type: 'text', disabled: true },
            { name: '兑换来源', fieldName: 'order_type', value: '', type: 'select', options: orderType, disabled: true },
            { name: '是否发货', fieldName: 'is_ship', value: '', type: 'select', options: status, disabled: true },
          ],
        },
        {
          name: '兑换信息',
          value: 'exchange',
          list: [
            { name: '收件人姓名', fieldName: 'recipient_name', value: '', type: 'text', required: true, disabled: true },
            { name: '收件人电话', fieldName: 'recipient_tel', value: '', type: 'text', required: true, disabled: true },
            { name: '备用电话', fieldName: 'recipient_tel_backup', value: '', type: 'text', required: false, disabled: true },
            {
              fieldName: 'recipient_province_id||recipient_city_id||recipient_area_id',
              type: 'cascader',
              options: [],
              name: '联系地址',
              value: [],
              required: true,
              remote: true,
              disabled: true,
              props: {
                url: '/common/area',
                value: 'value',
                label: 'label',
                params: { level: 3 },
              },
            },
            { name: '详细地址', fieldName: 'recipient_address', value: '', type: 'text', required: true, disabled: false, width: '50%' },
            { name: '兑换人姓名', fieldName: 'exchange_name', value: '', type: 'text', required: true, disabled: true },
            { name: '兑换人电话', fieldName: 'exchange_tel', value: '', type: 'text', required: true, disabled: true },
            { name: '备注', fieldName: 'memo', value: '', type: 'textarea', required: false, disabled: true, width: '100%' },
          ],
        },
        {
          name: '发货信息',
          value: 'shipping',
          list: [
            { name: '发货类型', fieldName: 'delivery_type', value: '', type: 'select', options: deliveryType, disabled: true, required: true },
            { name: '供应商', fieldName: 'supplier_id', value: '', type: 'select', options: [], disabled: true, required: true },
            { name: '是否结账', fieldName: 'is_checkout', value: '', type: 'select', options: status, disabled: true, required: true },
            { name: '预发货日期', fieldName: 'ship_date_ready', value: '', type: 'date', options: status, disabled: true, required: true },
            // { name: '虚拟卡号', fieldName: 'card_id', value: '', type: 'text', disabled: true },
            // { name: '虚拟密码', fieldName: 'card_pwd', value: '', type: 'text', disabled: true },
          ],
        },
      ];
    },
  },
};
</script>
