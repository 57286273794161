<template>
  <div>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      :title="title"
      :width="500"
      ref="lzDialog">
      <lz-form :form="form" :col="1" ref="lzForm"></lz-form>
    </lz-dialog>
    <lz-dialog title="快递信息" ref="lzExpressDialog">
      <iframe v-if="expressList.trailUrl" :src="expressList.trailUrl"></iframe>
      <el-timeline :reverse="false" style="padding: 20px;">
        <el-timeline-item
          v-for="(item, index) in expressList.data"
          :key="index"
          color="#0bbd87"
          :timestamp="item.time">
          {{ item.context }}
        </el-timeline-item>
      </el-timeline>
    </lz-dialog>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      default: '',
    },
  },
  data() {
    return {
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      title: '',
      form: [],
      trailUrl: '',
      smsList: [],
      expressList: [],
      questionList: [],
    };
  },
  mounted() {
    this.$baseHttp.get(`/sms/list?page_size=300&show_type=${this.type === 'order' ? '1,2' : '1,3'}`).then((response) => {
      this.smsList = response.data.data.filter((v) => Number(v.status) === 0).map((r) => ({
        ...r,
        label: r.name,
        value: r.id,
      }));
    });
  },
  methods: {
    init(type, value, expressId) {
      if (Array.isArray(value) && value.length === 0) {
        this.$message.error('请选择数据');
        return;
      }
      this.form = [];
      if (Array.isArray(value)) {
        this.form.push({ fieldName: 'ids', type: 'text', name: 'id', value: value.toString(), hide: true });
      } else {
        this.form.push({ fieldName: 'id', type: 'text', name: 'id', value, hide: true });
      }
      this.form.push({ fieldName: 'type', type: 'text', name: 'type', value: type, hide: true });
      switch (type) {
        case 'sms':
          this.title = '发送短信';
          this.form.push({ fieldName: 'value', type: 'select', name: '短信模版', required: true, value: '', options: this.smsList });
          // 快递id
          if (expressId) {
            this.form.push({ fieldName: 'express_id', type: 'text', name: 'express_id', hide: true, value: expressId });
          }
          break;
        case 'ship_date_ready':
          this.title = '预发货日期';
          this.form.push({ name: '预发货日期', type: 'date', fieldName: 'value', value: '' });
          break;
        case 'question':
          this.title = '修改状态';
          this.form.push({ fieldName: 'value', type: 'select', name: '选择状态', value: '', options: this.questionList, required: true });
          this.form.push({ fieldName: 'question_memo', type: 'textarea', name: '备注', value: '', required: true });
          break;
        default:
          break;
      }
      this.$refs.lzDialog.dialogVisible = true;
    },
    async handleGetExpress(item) {
      const params = {
        id: item.order_id,
        express_id: item.id,
      };
      const response = await this.$baseHttp.get('/order/express', { params });
      if (!response) return;
      if (response.data.length === 0) {
        this.$message.error('暂无数据');
        return;
      }
      if (response.data[0].message !== 'ok') {
        this.$message.error(response.data[0].message);
        return;
      }
      if (response.data[0].data.length === 0) {
        this.$message.error('暂无数据');
        return;
      }
      this.$refs.lzExpressDialog.dialogVisible = true;
      this.expressList = response.data[0];
    },
    async handleCommonBtnCLick() {
      const params = this.$refs.lzForm.getValue();
      if (!params) return;
      const response = await this.$baseHttp.post('/order/action', params);
      if (!response) return;
      this.$message.success('操作成功');
      this.$refs.lzDialog.dialogVisible = false;
      this.$emit('success');
    },
  },
};
</script>
<style lang="scss" scoped>
iframe {
  border: 0;
  width: 100%;
  height: 300px;
}
</style>
