<template>
  <div>
    <lz-dialog
      title="短信发送记录"
      :is-close-btn="false"
      ref="lzDialog">
      <lz-list
        :config="config"
        :table="table"
        :is-router="false"
        :search-btn="searchBtn"
        @table-btn-click="handleTableBtnClick"
        :search="search"
        ref="lzList">
      </lz-list>
    </lz-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search: [
        { name: '订单id', fieldName: 'order_id', value: '' },
      ],
      searchBtn: [],
      table: [
        { name: '模版id', prop: 'template_id', width: '100px' },
        { name: '电话', prop: 'phone', width: '100px' },
        { name: '内容', prop: 'content' },
        { name: '错误信息', prop: 'error', width: '100px' },
        { name: '发送时间', prop: 'create_time', width: '100px' },
        { name: '操作', prop: 'htmlButton' },
      ],
      form: [],
      config: {
        url: '/sms/log',
        setTableFormat: (list) => {
          list.forEach((el) => {
            this.$set(el, 'htmlButton', []);
            if (el.btn_resend) this.$set(el, 'htmlButton', [{ name: '重发', key: 'resend', type: 'warning' }]);
          });
          return list;
        },
      },
    };
  },
  methods: {
    init(id) {
      this.search[0].value = id;
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzList.getData();
      });
    },
    handleTableBtnClick(type, element) {
      switch (type) {
        case 'resend':
          this.$baseConfirm({
            content: '是否重新发送',
            callConfirm: async () => {
              const response = await this.$baseHttp.post('/sms/resend', {
                id: element.id,
              });
              if (!response) return;
              this.$message.success('操作成功');
            },
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
